(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("BasicBpcComponent"), require("COS"), require("Compressor"), require("ELEMENT"), require("Vue"), require("VueRouter"));
	else if(typeof define === 'function' && define.amd)
		define(["BasicBpcComponent", "COS", "Compressor", "ELEMENT", "Vue", "VueRouter"], factory);
	else if(typeof exports === 'object')
		exports["goodthinghub-bpc-subwebapp-app"] = factory(require("BasicBpcComponent"), require("COS"), require("Compressor"), require("ELEMENT"), require("Vue"), require("VueRouter"));
	else
		root["goodthinghub-bpc-subwebapp-app"] = factory(root["BasicBpcComponent"], root["COS"], root["Compressor"], root["ELEMENT"], root["Vue"], root["VueRouter"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__7513__, __WEBPACK_EXTERNAL_MODULE__78918__, __WEBPACK_EXTERNAL_MODULE__6238__, __WEBPACK_EXTERNAL_MODULE__83451__, __WEBPACK_EXTERNAL_MODULE__12740__, __WEBPACK_EXTERNAL_MODULE__17088__) {
return 