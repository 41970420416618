import Message from 'element-ui/lib/message'
import Loading from 'element-ui/lib/loading'
import MessageBox from 'element-ui/lib/message-box'
import Notification from 'element-ui/lib/notification'
import { i18n } from './i18n-setup'
import { pubsub } from '@/utils/pubsub'

// import 'element-ui/lib/theme-chalk/base.css'
// import 'element-ui/lib/theme-chalk/message.css'
// import 'element-ui/lib/theme-chalk/message-box.css'
// import 'element-ui/lib/theme-chalk/loading.css'
// import 'element-ui/lib/theme-chalk/notification.css'

pubsub.subscribe('i18nChange', () => {
  MessageBox.setDefaults({
    confirmButtonText: i18n.t('el.messagebox.confirm'),
    cancelButtonText: i18n.t('el.messagebox.cancel'),
  })
})

export { Message, Loading, MessageBox, Notification }
