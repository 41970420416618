export const outsideSalonRoutes = [
  {
    path: '/GoodtingHub_ExteriorSalon_Information_setting',
    name: 'GoodtingHub_ExteriorSalon_Information_setting',
    component: () =>
      import(
        /* webpackChunkName:"GoodtingHub_ExteriorSalon_Information_setting" */ '@/views/outsideSalon/salonSetting/YMain.vue'
      ),
    meta: {
      title: '沙龙设置',
    },
  },
  {
    path: '/GoodtingHub_ExteriorSalon_OrderSettlement',
    name: 'GoodtingHub_ExteriorSalon_OrderSettlement',
    component: () =>
      import(
        /* webpackChunkName:"GoodtingHub_ExteriorSalon_OrderSettlement" */ '@/views/outsideSalon/salonOrderAll/index.vue'
      ),
    meta: {
      title: '沙龙订单结算',
    },
  },
  {
    path: '/GoodtingHub_ExteriorSalon_GroupBuy',
    name: 'GoodtingHub_ExteriorSalon_GroupBuy',
    component: () =>
      import(
        /* webpackChunkName:"GoodtingHub_ExteriorSalon_GroupBuy" */ '@/views/outsideSalon/salonGroupBuy/YMain.vue'
      ),
    meta: {
      title: '沙龙团购',
    },
  },
]
