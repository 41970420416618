import { outsideSalonRoutes } from './outsideSalon'
import { salonRoutes } from './salon'

export const routes = [
  {
    path: '/',
    redirect: '/GoodtingHub_SKU',
  },
  // 商城管理
  {
    path: '/GoodtingHub_SKU',
    name: 'GoodtingHub_SKU',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_SKU" */ '@/views/goodsManage/goodsInfoManage/goodsSku/main.vue'
      ),
    children: [],
  },
  {
    path: '/GoodtingHub_shopDesign',
    name: 'GoodtingHub_shopDesign',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_shopDesign" */ '@/views/goodsManage/goodsInfoManage/shopDesign/mainPage.vue'
      ),
    meta: {
      title: '店铺设计',
    },
  },
  {
    path: '/GoodthingHub_MBSetting',
    name: 'GoodthingHub_MBSetting',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_MBSetting" */ '@/views/goodsManage/shopRuleSetting/index.vue'
      ),
    meta: {
      title: '平台规则设置',
    },
  },
  {
    path: '/GoodthingHub_GoodsSortSetting',
    name: 'GoodthingHub_GoodsSortSetting',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_GoodsSortSetting" */ '@/views/goodsManage/goodsSortSetting/index.vue'
      ),
    meta: {
      title: '商品权重设置',
    },
  },
  // 订单管理
  {
    path: '/GoodthingHub_Order',
    name: 'GoodthingHub_Order',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_rebackAddress" */ '@/views/goodsManage/goodsInfoManage/goodsOrder/main.vue'
      ),
  },
  // 自定义字段
  {
    path: '/GoodtingHub_goodsCustomFields',
    name: 'GoodtingHub_goodsCustomFields',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_goodsCustomFields" */ '@/views/goodsManage/goodsSetting/goodsCustomFields/index.vue'
      ),
  },
  // 商品分类
  {
    path: '/GoodtingHub_goodsType',
    name: 'GoodtingHub_goodsType',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_goodsType" */ '@/views/goodsManage/goodsSetting/goodsType'
      ),
  },
  // 商品规格
  {
    path: '/GoodtingHub_goodsSpecs',
    name: 'GoodtingHub_goodsSpecs',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_goodsSpecs" */ '@/views/goodsManage/goodsSetting/goodsSpecs'
      ),
  },
  // 商品推广页面
  {
    path: '/GoodtingHub_goodsPromotePage',
    name: 'GoodtingHub_goodsPromotePage',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_goodsPromotePage" */ '@/views/goodsManage/goodsPromote'
      ),
  },

  /// 分销代理

  // 代理等级
  {
    path: '/GoodthingHub_distributionLevel',
    name: 'GoodthingHub_distributionLevel',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_distributionLevel" */ '@/views/agentManage/distributionLevel/index.vue'
      ),
    meta: {
      title: '代理等级',
    },
  },
  {
    path: '/GoodthingHub_distributionLevel_setting',
    name: 'GoodthingHub_distributionLevel_setting',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_distributionLevel_setting" */ '@/views/agentManage/distributionLevel/setting/index.vue'
      ),
    meta: {
      title: '代理设置',
    },
  },
  // 代理商列表
  {
    path: '/GoodthingHub_agentsManage',
    name: 'GoodthingHub_agentsManage',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_agentsManage" */ '@/views/agentManage/agentsManage/main.vue'
      ),
    meta: {
      title: '代理商列表',
    },
  },
  /// 代理商排行
  {
    path: '/Goodthinghub_agentsBoard',
    name: 'Goodthinghub_agentsBoard',
    component: () =>
      import(
        /* webpackChunkName: "Goodthinghub_agentsBoard" */ '@/views/agentManage/agentsboard'
      ),
    meta: {
      title: '代理商排行',
    },
  },
  // 代理云仓
  {
    path: '/Goodthinghub_agentCloudwareHouse',
    name: 'Goodthinghub_agentCloudwareHouse',
    component: () =>
      import(
        /* webpackChunkName: "Goodthinghub_agentCloudwareHouse" */ '@/views/agentManage/agentsManage/agentCloudwareHouse'
      ),
    meta: {
      title: '代理云仓',
    },
  },
  {
    path: '/GoodthingHub_distributionOrder',
    name: 'GoodthingHub_distributionOrder',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_distributionOrder" */ '@/views/agentManage/distributionOrder'
      ),
    meta: {
      title: '代理分销订单',
    },
  },
  {
    path: '/GoodthingHub_cloudWarehouseManage',
    name: 'GoodthingHub_cloudWarehouseManage',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_cloudWarehouseManage" */ '@/views/cloudWarehouse/cloudWarehouseManage'
      ),
    meta: {
      title: '云仓管理',
    },
  },
  {
    path: '/GoodthingHub_cloudWarehouseSettings',
    name: 'GoodthingHub_cloudWarehouseSettings',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_cloudWarehouseSettings" */ '@/views/cloudWarehouse/cloudWarehouseManage/cloudWarehouseSettings'
      ),
    meta: {
      title: '云仓设置',
    },
  },
  {
    path: '/GoodthingHub_cashOutManage',
    name: 'GoodthingHub_cashOutManage',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_cashOutManage" */ '@/views/cashOutManage/withdrawalSubsidiary'
      ),
    meta: {
      title: '提现明细',
    },
  },
  {
    path: '/GoodthingHub_cashOutDetail',
    name: 'GoodthingHub_cashOutDetail',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_cashOutDetail" */ '@/views/cashOutManage/cashOutDetail'
      ),
    meta: {
      title: '提现详情',
    },
  },
  /********* 优惠券路由 **********/
  {
    path: '/GoodtingHub_coupon',
    name: 'GoodtingHub_coupon',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_coupon" */ '@/views/couponManage/coupon/main'
      ),
    meta: {
      title: '优惠券管理',
    },
  },
  // {
  //   path: '/GoodtingHub_couponRelease',
  //   name: 'GoodtingHub_couponRelease',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "GoodtingHub_couponRelease" */ '@/views/couponManage/coupon/record'
  //     ),
  //   meta: {
  //     title: '优惠券发放',
  //   },
  // },
  {
    path: '/GoodtingHub_couponRelease',
    name: 'GoodtingHub_couponRelease',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_couponRelease" */ '@/views/couponManage/couponRelease/main.vue'
      ),
    meta: {
      title: '优惠券发放方案',
    },
  },
  {
    path: '/GoodtingHub_couponUsingLog',
    name: 'GoodtingHub_couponUsingLog',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_couponUsingLog" */ '@/views/couponManage/couponUsingLog'
      ),
    meta: {
      title: '优惠券使用记录',
    },
  },
  {
    path: '/GoodtingHub_couponReleaseStatistic',
    name: 'GoodtingHub_couponReleaseStatistic',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_couponReleaseStatistic" */ '@/views/couponManage/couponReleaseStatistic'
      ),
    meta: {
      title: '优惠券发放统计',
    },
  },
  /// 金刚区设置
  {
    path: '/GoodthingHub_MallSetiting',
    name: 'GoodthingHub_MallSetiting',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_MallSetiting" */ '@/views/goodsManage/MallSetiting/main'
      ),
    meta: {
      title: '优惠券发放统计',
    },
  },
  /******** 内容运营管理 ***********/
  {
    path: '/GoodtingHub_intraAccount',
    name: 'GoodtingHub_intraAccount',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_intraAccount" */ '@/views/contentManage/accountManage/index.vue'
      ),
    meta: {
      title: '运营账号管理',
    },
  },
  {
    path: '/GoodtingHub_postTag',
    name: 'GoodtingHub_postTag',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_postTag" */ '@/views/contentManage/tagManage/myMain.vue'
      ),
    meta: {
      title: '内容标签管理',
    },
  },
  {
    path: '/GoodthingHub_Service',
    name: 'GoodthingHub_Service',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_Service" */ '@/views/goods_service/index.vue'
      ),
    meta: {
      title: '售后管理',
    },
  },
  {
    path: '/GoodtingHub_postManage',
    name: 'GoodtingHub_postManage',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_postManage" */ '@/views/postManage/index.vue'
      ),
    meta: {
      title: '售后管理',
    },
  },
  {
    path: '/GoodtingHub_goodsTag',
    name: 'GoodtingHub_goodsTag',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_goodsTag" */ '@/views/goodsTag/index.vue'
      ),
    meta: {
      title: '售后管理',
    },
  },
  /// 用户标签
  {
    path: '/GoodtingHub_userTag',
    name: 'GoodtingHub_userTag',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_userTag" */ '@/views/contentManage/userTagManage/index.vue'
      ),
    meta: {
      title: '用户标签页面',
    },
  },
  /// 用户互动
  {
    path: '/GoodtingHub_userInteract',
    name: 'GoodtingHub_userInteract',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_userInteract" */ '@/views/contentManage/userInteractManage/index.vue'
      ),
    meta: {
      title: '用户互动页面',
    },
  },
  // 系统通知
  {
    path: '/GoodtingHub_systemMessage',
    name: 'GoodtingHub_systemMessage',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_systemMessage" */ '@/views/systemMessage/index.vue'
      ),
    meta: {
      title: '系统通知',
    },
  },
  {
    path: '/GoodtingHub_shopFollowers',
    name: 'GoodtingHub_shopFollowers',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_shopFollowers" */ '@/views/shopfollowers.vue'
      ),
    meta: {
      title: '店铺粉丝',
    },
  },
  {
    path: '/GoodtingHub_postReview',
    name: 'GoodtingHub_postReview',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_postReview" */ '@/views/postReview/index.vue'
      ),
    meta: {
      title: '内容审查',
    },
  },
  /// 代理日志
  {
    path: '/GoodthingHub_agentLog',
    name: 'GoodthingHub_agentLog',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_agentLog" */ '@/views/agentManage/agentsManage/agentLog/index.vue'
      ),
    meta: {
      title: '内容审查',
    },
  },

  /// 营销管理
  /// 名单管理
  {
    path: '/GoodtingHub_nameList',
    name: 'GoodtingHub_nameList',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_nameList" */ '@/views/markingManage/nameList/main.vue'
      ),
    meta: {
      title: '名单管理',
    },
  },
  /// 营销话术管理
  {
    path: '/GoodtingHub_marketingSpeech',
    name: 'GoodtingHub_marketingSpeech',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_marketingSpeech" */ '@/views/markingManage/marketingSpeech/cpn.vue'
      ),
    meta: {
      title: '营销话术管理',
    },
  },
  /// 营销推送
  {
    path: '/GoodtingHub_salesPush',
    name: 'GoodtingHub_salesPush',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_salesPush" */ '@/views/markingManage/salesPush/index.vue'
      ),
    meta: {
      title: '营销推送',
    },
  },
  /// 效果跟踪
  {
    path: '/GoodtingHub_effectTracking',
    name: 'GoodtingHub_effectTracking',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_effectTracking" */ '@/views/markingManage/effectTracking/index.vue'
      ),
    meta: {
      title: '效果跟踪',
    },
  },
  // 会员体系
  {
    path: '/Goodthinghub_membershipSetting',
    name: 'Goodthinghub_membershipSetting',
    component: () =>
      import(
        /* webpackChunkName: "Goodthinghub_membershipSetting" */ '@/views/membershipSystem/YMain.vue'
      ),
    meta: {
      title: '会员体系',
    },
  },
  // 会员体系管理
  {
    path: '/GoodthingHub_membershipsManage',
    name: 'GoodthingHub_membershipsManage',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_membershipsManage" */ '@/views/membershipManage/index.vue'
      ),
    meta: {
      title: '会员体系管理',
    },
  },
  // 运营数据看板工作台
  {
    path: '/goodthinghub_mbDashboard',
    name: 'goodthinghub_mbDashboard',
    component: () =>
      import(
        /* webpackChunkName: "goodthinghub_mbDashboard" */ '@/views/mbDashboard/index.vue'
      ),
    meta: {
      title: '运营数据看板',
    },
  },
  ...salonRoutes,
  ...outsideSalonRoutes,
]

// 所有的路由
export const getAllRoutes = (r = []) => {
  if (!r?.length) r = routes
  return r.map((route) => {
    if (route.children?.length) {
      return getAllRoutes(route.children)
    }
    return {
      path: route.path,
      name: route.name,
    }
  })
}
