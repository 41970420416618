import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '@/App.vue'
import { routes } from '@/router'
// import store from '@/store'
import { name } from '../package.json'
import dayjs from 'dayjs'
import PubSub from 'pubsub-js'

// 国际化
import { i18n } from '@/setup/i18n-setup'
import 'normalize.css'
import '@/styles/index.scss'
import BasicBpcComponent from '@mybeauty/basic-bpc-component'
import '@mybeauty/basic-bpc-component/BasicBpcComponent.css'
import { useLoading } from '@/utils/loading'
import { getAllRoutes } from './router/index'
import { Loading, MessageBox, Message, Notification } from '@/setup/elementUI'
import '@/directives/index'

console.log(process.env.VUE_APP_ENV)

// const { start, close } = useLoading()
const allRoutes = getAllRoutes().map((item) => item.path)

Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs
Vue.prototype.$pubsub = PubSub

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

Vue.prototype.$ELEMENT = {
  i18n: (key, value) => i18n.t(key, value),
  size: 'small',
  zIndex: 2000,
}

// 国际化旧方案
Vue.use(VueRouter)
// Vue.use(ElementUi, {
//   i18n: (key, value) => i18n.t(key, value),
//   size: 'small',
//   zIndex: 1000,
// })
Vue.use(BasicBpcComponent, {
  mbI18n: (key, value) => i18n.t(key, value),
  getI18n: () => i18n,
})

export let router = null
export let instance = null
let store = null
async function render(props = {}) {
  // 接受主应用下发的路由作为子应用的路由base路径
  const { activeRule, btnAuth } = props
  const { startLoading, closeLoading } = useLoading()
  store = (await import('@/store')).default
  // 父应用下发的按钮权限指令
  if (btnAuth) {
    Vue.directive(btnAuth.name, {
      ...btnAuth,
    })
  }

  // vite开发 作为单独运行的实例
  if (process.env.IS_VITE) {
    const ELEMENT = await import('element-ui')
    Vue.use(ELEMENT)
    import('@mybeauty/basic-bpc-component/BasicBpcComponent.css')
    const { viteRouter } = await import('@/localViews/index')
    router = await viteRouter()
  } else {
    router = new VueRouter({
      base: window.__POWERED_BY_QIANKUN__ ? `/${activeRule}/` : '/',
      mode: 'history',
      routes,
    })

    router.beforeEach(async (to, _, next) => {
      const inKeeps = store.state.keepAliveIncludes.some(
        (item) => item.path === `/${activeRule}${to.path}`
      )
      if (!inKeeps && allRoutes.includes(to.path)) {
        startLoading()
      }
      // 如果组件没有名字，则自动添加名字，主要用于keep-alive
      if (typeof to.matched[0]?.components.default === 'function') {
        const d = await to.matched[0].components.default()
        if (!d.default.name || /index/i.test(d.default.name)) {
          d.default.name = /index/i.test(d.default.name)
            ? `${d.default.name}-${Date.now()}`
            : to.name
        }
        to.meta.componentName = d.default.name
        // 需要keep-alive的组件
        const k = store.state.keepAliveIncludes.find(
          (item) => item.path === `/${activeRule}${to.path}`
        )
        if (!k) {
          store.state.keepAliveIncludes.push({
            path: `/${activeRule}${to.path}`,
            componentName: to.meta.componentName,
            keep: true,
          })
        }
      }
      next()
    })

    router.afterEach(() => {
      closeLoading()
    })
  }

  // 实例化 Vue，不执行挂载逻辑，需要等国际化加载成功后再进行挂载
  instance = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  })
}

// TODO:将是否是子应用的标识存在Vuex中
if (window.__POWERED_BY_QIANKUN__) {
  // eslint-disable-next-line no-undef,camelcase
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  Vue.prototype.$loadLanguageAsync('zh-CN')
  render()
  const timer = setInterval(() => {
    if (instance) {
      instance.$mount(`#${name}`)
      clearInterval(timer)
    }
  }, 1000)
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}

export async function mount(props) {
  console.log('[vue] props from main framework', props)
  const { container, useLoading: fatherUserLoading } = props
  const { startLoading, closeLoading } = useLoading(fatherUserLoading)

  const unique = `${name} mount`
  startLoading({ unique })

  // 挂载Vue
  const _mountedVue = () => {
    if (instance.$el) {
      closeLoading(unique)
      return
    }
    // 挂载成功后关闭 loading
    instance.$once('hook:mounted', () => {
      console.log(`${name} 子应用挂载完成`)
      closeLoading(unique)
    })
    // 等加载好国际化之后再挂载，避免出现乱码
    instance.$mount(
      container ? container.querySelector(`#${name}`) : `#${name}`
    )
  }

  props.onGlobalStateChange(async (state) => {
    // state: 变更后的状态; prev 变更前的状态
    props.state = state
    const { locale, keepAliveIncludes } = state
    try {
      await Vue.prototype.$loadLanguageAsync(locale || 'zh-CN').then(() => {
        Vue.prototype.$pubsub.publish('i18nChange')
      })
    } catch (error) {
      console.log(error, '子应用设置国际化失败')
    } finally {
      if (instance) {
        _mountedVue()
      } else {
        // instance 可能还没有，所以需要轮询
        const timer = setInterval(() => {
          if (instance) {
            clearInterval(timer)
            _mountedVue()
          }
        }, 300)
      }
    }

    window.keepAliveIncludes = keepAliveIncludes
    store?.state.keepAliveIncludes.forEach((keep) => {
      if (keepAliveIncludes?.some((item) => item.path === keep.path)) {
        keep.keep = true
      } else {
        keep.keep = false
      }
    })
  })
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
  store = null
  console.log(`${name} 子应用卸载完成`)
}
