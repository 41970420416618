export default {
  name: 'dropSpace',
  bind(el) {
    let inputEle
    if (el.tagName === 'INPUT') {
      inputEle = el
    } else {
      inputEle = el.querySelector('input')
    }
    if (!inputEle) return
    // 使用UIEvent事件可以触发原生的事件
    const uiEvent = new UIEvent('input')
    inputEle.addEventListener('input', (e) => {
      const { value } = e.target
      const match = value.match(/ /)
      if (match?.index !== undefined) {
        let selectionStart = e.target.selectionStart
        const spaceCount =
          value.substring(0, selectionStart).match(/ /g)?.length || 0
        selectionStart -= spaceCount
        e.target.value = value.replaceAll(' ', '')
        e.target.setSelectionRange(selectionStart, selectionStart)
        e.target.dispatchEvent(uiEvent)
      }
    })
  },
}
