export const salonRoutes = [
  {
    path: '/GoodthingHub_salonSetting',
    name: 'GoodthingHub_salonSetting',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_salonSetting" */ '@/views/salon/salonSetting/YMain.vue'
      ),
    meta: {
      title: '店铺管理',
    },
  },
  {
    path: '/GoodthingHub_salonProducts',
    name: 'GoodthingHub_salonProducts',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_salonProducts" */ '@/views/salon/salonProducts/YMain.vue'
      ),
    meta: {
      title: '服务管理',
    },
  },
  {
    path: '/GoodthingHub_salonCustomer',
    name: 'GoodthingHub_salonCustomer',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_salonCustomer" */ '@/views/salon/salonCustomer/index.vue'
      ),
    meta: {
      title: '客户管理',
    },
  },
  {
    path: '/GoodthingHub_salonArtificer',
    name: 'GoodthingHub_salonArtificer',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_salonArtificer" */ '@/views/salon/salonArtificer/index.vue'
      ),
    meta: {
      title: '技师管理',
    },
  },
  {
    path: '/GoodthingHub_salonAppointment',
    name: 'GoodthingHub_salonAppointment',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_salonAppointment" */ '@/views/salon/salonAppointment/index.vue'
      ),
    meta: {
      title: '预约服务安排',
    },
  },
  {
    path: '/GoodtingHub_appointmentAll',
    name: 'GoodtingHub_appointmentAll',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_appointmentAll" */ '@/views/salon/salonAppointmentAll/index.vue'
      ),
    meta: {
      title: '预约服务安排',
    },
  },
  {
    path: '/GoodthingHub_salonArtificerPlan',
    name: 'GoodthingHub_salonArtificerPlan',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_salonArtificerPlan" */ '@/views/salon/salonArtificerPlan/index.vue'
      ),
    meta: {
      title: '服务技师安排',
    },
  },
  {
    path: '/GoodthingHub_salonArtificerV2',
    name: 'GoodthingHub_salonArtificerV2',
    component: () =>
      import(
        /* webpackChunkName: "GoodthingHub_salonArtificerV2" */ '@/views/salon/salonArtificerV2/index.vue'
      ),
    meta: {
      title: '服务技师安排',
    },
  },
  {
    path: '/GoodtingHub_salonReview',
    name: 'GoodtingHub_salonReview',
    component: () =>
      import(
        /* webpackChunkName: "GoodtingHub_salonReview" */ '@/views/salon/salonReview/index.vue'
      ),
    meta: {
      title: '沙龙审核',
    },
  },
  {
    path: '/GoodthingHub_salonPackage',
    name: 'GoodthingHub_salonPackage',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_salonPackage" */ '@/views/salon/salonPackage/index.vue'
      ),
    meta: {
      title: '服务套餐',
    },
  },
  {
    path: '/GoodthingHub_salonRecharge',
    name: 'GoodthingHub_salonRecharge',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_salonRecharge" */ '@/views/salon/salonRecharge/index.vue'
      ),
    meta: {
      title: '服务套餐',
    },
  },
  {
    path: '/GoodthingHub_salonOrder',
    name: 'GoodthingHub_salonOrder',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_salonOrder" */ '@/views/salon/salonOrder/index.vue'
      ),
    meta: {
      title: '沙龙订单',
    },
  },
  {
    path: '/GoodthingHub_salonOrderAll',
    name: 'GoodthingHub_salonOrderAll',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_salonOrderAll" */ '@/views/salon/salonOrderAll/index.vue'
      ),
    meta: {
      title: '沙龙订单统计',
    },
  },
  {
    path: '/GoodthingHub_salonDashboard',
    name: 'GoodthingHub_salonDashboard',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_salonDashboard" */ '@/views/salon/salonDashboard/index.vue'
      ),
    meta: {
      title: '沙龙工作台',
    },
  },
  {
    path: '/GoodtingHub_serviceCategory',
    name: 'GoodtingHub_serviceCategory',
    component: () =>
      import(
        /* webpackChunkName:"GoodtingHub_serviceCategory" */ '@/views/salon/salonServiceCategory/index.vue'
      ),
    meta: {
      title: '沙龙服务分类',
    },
  },
  {
    path: '/GoodthingHub_salonCoupon',
    name: 'GoodthingHub_salonCoupon',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_salonCoupon" */ '@/views/salon/salonCoupon/main.vue'
      ),
    meta: {
      title: '沙龙优惠券',
    },
  },
  // 沙龙商城页设置(从GoodthingHub_MallSetiting中复制过来)
  {
    path: '/GoodthingHub_salonAds',
    name: 'GoodthingHub_salonAds',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_salonAds" */ '@/views/salon/salonAds/main.vue'
      ),
    meta: {
      title: '沙龙优惠券',
    },
  },
  {
    path: '/GoodthingHub_freeServiceSetup',
    name: 'GoodthingHub_freeServiceSetup',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_freeServiceSetup" */ '@/views/salon/salonFreeServiceSetup/index.vue'
      ),
    meta: {
      title: '免费服务设置',
    },
  },
  {
    path: '/GoodthingHub_groupBuy',
    name: 'GoodthingHub_groupBuy',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_groupBuy" */ '@/views/salon/salonGroupBuy/index.vue'
      ),
    meta: {
      title: '团购管理',
    },
  },
  {
    path: '/GoodthingHub_allGroupBuy',
    name: 'GoodthingHub_allGroupBuy',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_allGroupBuy" */ '@/views/salon/salonGroupBuyAll/index.vue'
      ),
    meta: {
      title: '平台团购管理',
    },
  },
  {
    path: '/GoodthingHub_allServices',
    name: 'GoodthingHub_allServices',
    component: () =>
      import(
        /* webpackChunkName:"GoodthingHub_allServices" */ '@/views/salon/salonAllServices/index.vue'
      ),
    meta: {
      title: '平台服务管理',
    },
  },
  {
    path: '/GoodtingHub_MBCoins',
    name: 'GoodtingHub_MBCoins',
    component: () =>
      import(
        /* webpackChunkName:"GoodtingHub_MBCoins" */ '@/views/salon/salonMbCoins/index.vue'
      ),
    meta: {
      title: '积分币设置',
    },
  },
  {
    path: '/GoodtingHub_MBCoins_Exchange',
    name: 'GoodtingHub_MBCoins_Exchange',
    component: () =>
      import(
        /* webpackChunkName:"GoodtingHub_MBCoins_Exchange" */ '@/views/salon/salonMbCoinsExchange/pubsub-main.vue'
      ),
    meta: {
      title: '积分币设置',
    },
  },
]
