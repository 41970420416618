<template>
  <div :id="name">
    <!-- 测试国际化 -->
    <!-- <div>{{ $t('test') }}</div> -->
    <keep-alive :include="$store.getters.keeps">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { name } from '../package.json'
export default {
  name: 'App',
  data() {
    return {
      name,
    }
  },
  mounted() {
    /// 设置商铺全局上下问
    // this.$store.dispatch('shopModule/setShopInfo')
  },
}
</script>
